<template>
  <span v-if="passed > 0">{{ text }}</span>
  <em class="text-info" v-else>None</em>
</template>

<script>
import secondsToTime from '@/modules/filters/secondsToTime'

export default {
  name: 'TimePassed',
  props: {
    start: { type: Date, required: true }
  },
  computed: {
    text () {
      if (this.passed > 8553600) {
        clearInterval(this.interval)
        return '99+ Days'
      }

      return secondsToTime(this.passed)
    }
  },
  data () {
    return {
      now: new Date(),
      passed: 0,
      interval: undefined
    }
  },
  watch: {
    start () { this.init() }
  },
  created () {
    this.init()
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    init () {
      this.passed = 0
      clearInterval(this.interval)

      const startEpoch = this.start.getTime()

      if (isNaN(startEpoch) || startEpoch === 0) return

      this.passed = Number((this.now.getTime() - startEpoch) / 1000).toFixed()

      this.interval = setInterval(() => { this.passed++ }, 1000)
    }
  }
}
</script>
